import * as React from 'react'
import styled from '@emotion/styled'
import { colors } from '../../styles/variables'
import Container from '../Container'

const Wrapper = styled.section`
  display: flex;
  align-content: center;
  align-items: center;
  //background-color: ${colors.white};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 96%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  hr {
    width: 100%;
  }
`

const Intro: React.FC = ({ children }) => (
  <Wrapper>
    <Container>
      <Content>{children}</Content>
    </Container>
  </Wrapper>
)

export default Intro
