import * as React from 'react'
import { Layers } from 'styled-icons/feather/Layers'
import { Box, Column, Section, Tag } from 'rbx'
import { css } from '@emotion/core'
import { Cpu } from 'styled-icons/feather/Cpu'
import { PackageIcon } from 'styled-icons/feather/PackageIcon'
import { ButtonGroup } from 'rbx/elements/button/button-group'
import { graphql } from 'gatsby'

import CoupleShopping from 'assets/images/couple-shopping.jpg'
import ApparelOnShelves from 'assets/images/assorted-apparel-on-shelves.jpg'
import ClothesOnHangers from 'assets/images/clothes-on-hangers.jpg'
import OnlineStoreManagerInPublic from 'assets/images/online-store-manager-in-public.jpg'
import SellerWithBracelet from 'assets/images/seller-with-bracelet.jpg'

import Page from '../../components/Page'
import IndexLayout from '../../layouts'
import StyledContainer from '../../components/Container'
import { ColoredPanel, Panel } from '../../components/Panel'
import { colors } from '../../styles/variables'
import Statement from '../../components/Statement'
import { StyledParagraph } from '../../components/Base'
import { StyledHeading } from '../../components/Heading'
import { StyledButton } from '../../components/Button'
import Intro from '../../components/Intro/intro'
import StyledImg from '../../components/Img'

const IndexPage = ({ data }) => {
  console.log(data)
  return (
    <IndexLayout>
      <Page>
        <ColoredPanel
          background={colors.brand.main}
          margin="0"
          css={css`
            * {
              color: ${colors.white};
            }
          `}
        >
          <StyledContainer>
            <Statement>
              <div>
                <Tag color="warning">Exciting new features shipping in Version 4!</Tag>
              </div>
              <Layers
                color={colors.white}
                size={56}
                css={css`
                  margin-top: 1.2rem;
                  margin-bottom: 0.6rem;
                `}
              />
              <h1>Bringing you a new way of upping your game</h1>
              <p
                css={css`
                  padding-top: 0.6rem;
                  color: ${colors.ui.bright};
                `}
              >
                With features that put you above your competitors, and keep your customers excited!
              </p>
            </Statement>

            <Box
              css={css`
                padding: 0;
                height: 580px;
              `}
            >
              <StyledImg
                src={SellerWithBracelet}
                css={css`
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                `}
              />
            </Box>
          </StyledContainer>
        </ColoredPanel>

        <Section
          css={css`
            position: relative;
            background: linear-gradient(0deg, #fbfafe 95px, #fff);

            :after {
              position: absolute;
              height: 254px;
              width: 100%;
              left: 0;
              bottom: -250px;
              background: linear-gradient(-10deg, #fff, #fff 50%, #fbfafe 0);
              content: '';
            }
          `}
        >
          <StyledContainer>
            <Column.Group gapSize={8}>
              <Column>
                <Cpu size={36} color={colors.accent} />
                <h3>Centralized Order Fulfillment</h3>
                <p>
                  Monitor your orders from all connected stores and fulfill them in record time at one place. No need to set up different
                  order processing and fulfilment processes on different stores. Have a peace of mind, and fulfill all orders from different
                  stores at one place.{' '}
                </p>
              </Column>
              <Column>
                <Box
                  css={css`
                    padding: 0;
                    height: 360px;
                  `}
                >
                  <StyledImg
                    src={CoupleShopping}
                    css={css`
                      height: 100%;
                      object-fit: cover;
                    `}
                  />
                </Box>
              </Column>
            </Column.Group>
          </StyledContainer>
        </Section>

        <Section
          css={css`
            position: relative;
            z-index: 5;
          `}
        >
          <StyledContainer>
            <Column.Group
              gapSize={8}
              css={css`
                align-items: center;
              `}
            >
              <Column>
                <Box
                  css={css`
                    padding: 0;
                    height: 480px;
                  `}
                >
                  <StyledImg
                    src={ApparelOnShelves}
                    css={css`
                      height: 100%;
                      object-fit: cover;
                    `}
                  />
                </Box>
              </Column>

              <Column>
                <PackageIcon size={36} color={colors.accent} />
                <h3>Auto-Import Orders</h3>
                <p>
                  Have your orders automatically imported from the different stores you sell on, into OrderSenta and your fulfilment
                  platform. In an instant, your orders are made available for processing.
                </p>
              </Column>
            </Column.Group>
          </StyledContainer>
        </Section>

        <Section
          css={css`
            position: relative;
            padding-top: 160px;
            background: linear-gradient(180deg, #fbfafe 95px, #fff);

            :after {
              position: absolute;
              height: 254px;
              width: 100%;
              left: 0;
              top: -130px;
              background: linear-gradient(170deg, #fff, #fff 50%, #fbfafe 0);
              content: '';
            }
          `}
        >
          <StyledContainer>
            <Column.Group gapSize={8}>
              <Column>
                <Cpu size={36} color={colors.accent} />
                <h3>Powerful synchronization</h3>
                <p>
                  In real time, the system automatically sends updates to orders such as generated tracking numbers to the channels the
                  items were purchased on, and thereby to the buyers.
                </p>
              </Column>
              <Column>
                <Box
                  css={css`
                    padding: 0;
                    height: 480px;
                  `}
                >
                  <StyledImg
                    src={ClothesOnHangers}
                    css={css`
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    `}
                  />
                </Box>
              </Column>
            </Column.Group>

            <Panel padding="4.8rem 0 0">
              <Box
                css={css`
                  padding: 0;
                  text-align: center;
                `}
              >
                <Column.Group gapless>
                  <Column>
                    <div
                      css={css`
                        margin: 0;
                        background-color: ${colors.accent};
                        padding: 3.6rem;

                        * {
                          color: white;
                        }
                      `}
                    >
                      <h2>Analytics and Insights</h2>
                      <StyledParagraph>
                        Get a quick insight into how your products are performing on all the channels you sell on, and which channels are
                        contributing most to your total sales.
                        <br /> <br />
                        Make informed business decisions to keep your business growing based on the easy to understand data we present to
                        you.
                      </StyledParagraph>
                    </div>
                  </Column>

                  <Column>
                    <Box
                      css={css`
                        border-radius: 0;
                        box-shadow: none;
                        padding: 0;
                        height: 100%;
                      `}
                    >
                      <StyledImg
                        src={OnlineStoreManagerInPublic}
                        css={css`
                          width: 100%;
                          height: 100%;
                          object-fit: cover;
                        `}
                      />
                    </Box>
                  </Column>
                </Column.Group>
              </Box>
            </Panel>
          </StyledContainer>
        </Section>

        <ColoredPanel background={colors.brand.darker}>
          <StyledContainer>
            <Statement
              css={css`
                * {
                  color: white;
                }
              `}
            >
              <StyledHeading>Your specific needs in mind.</StyledHeading>
              <StyledParagraph
                css={css`
                  color: ${colors.ui.bright};
                `}
                padding="0"
              >
                OrderSenta is built with the everyday merchant at heart. We provide you functionalities and automations to make your life as
                a merchant a bliss.
              </StyledParagraph>
            </Statement>
          </StyledContainer>
        </ColoredPanel>
        <StyledContainer>
          <Intro>
            <div
              css={css`
                padding-bottom: 4.8rem;
              `}
            >
              <StyledHeading>Sync orders with OrderSenta today</StyledHeading>
              <p>Sign up today for free. No credit card required. Account can be cancelled at any time.</p>
              <ButtonGroup
                css={css`
                  justify-content: center;
                `}
              >
                <StyledButton color="primary" as="a" href={`${process.env.API_URL}/auth/signup`}>
                  Start free 14-day trial
                </StyledButton>
                <StyledButton>Contact sales</StyledButton>
              </ButtonGroup>
            </div>
          </Intro>
        </StyledContainer>
      </Page>
    </IndexLayout>
  )
}

export default IndexPage

export const query = graphql`
  {
    allPrismicFeatures {
      edges {
        node {
          id
          data {
            body {
              id
              items {
                description {
                  html
                  text
                }
                image {
                  alt
                  copyright
                  url
                }
                title {
                  html
                  text
                }
              }
            }
            page_title {
              html
              text
            }
          }
        }
      }
    }
  }
`
